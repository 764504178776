<template>
  <Popup ref="popupRef" width="500px" title="顾问换绑" confirm-text="确认" :confirmLoading="confirmLoading" @confirm="confirm" @close="initData">
    <el-select style="width: 100%;" v-model="newBid" placeholder="请选择目标顾问">
      <el-option
        v-for="item in counselorList"
        :key="item.bid"
        :label="item.name"
        :value="item.bid">
      </el-option>
    </el-select>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { queryContractsApi, accessibleConsultantsApi, contractManageApi } from '@/api/admin.js'
export default {
  components: {
    Popup
  },
  data() {
    return {
      counselorList: [],
      sids: [],
      newBid: '',
      confirmLoading: false
    }
  },
  methods: {
    open(option) {
      if(option?.sids?.length > 0) {
        this.sids = option.sids
        this.$refs.popupRef.open()
        this.getCounselorList()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    initData() {
      this.sids = []
      this.newBid = ''
    },
    getCounselorList() {
      accessibleConsultantsApi().then(res => {
        console.log('全部商务顾问', res)
        this.counselorList = res.data?.consultantList || []
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    confirm() {
      if(!this.newBid) return this.$tips({message: '请选择要换绑的顾问', type:'warning'})
      const formData = {
        act: 3,
        newBid: this.newBid,
        sids: this.sids
      }
      console.log('formData', formData)
      this.confirmLoading = true
      contractManageApi(formData).then(res => {
        this.confirmLoading = false
        this.$tips({message: res.msg, type:'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.confirmLoading = false
        this.$tips({message: err, type:'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>